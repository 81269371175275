$(document).on('turbolinks:load', function () {


    var login_form_faq_button_1 = document.querySelector('#login_form_faq_button_1');

    if (login_form_faq_button_1 != undefined) {

        $('#login_form_faq_button_1').click(function () {
            event.preventDefault();
            if ( $("#login_form_faq_text_1").first().is( ":hidden" ) ) {
                $( "#login_form_faq_text_1" ).slideDown( "slow" );
            } else {
                $( "#login_form_faq_text_1" ).slideUp( "slow" );
            }
        });

        $('#login_form_faq_button_2').click(function () {
            event.preventDefault();
            if ( $("#login_form_faq_text_2").first().is( ":hidden" ) ) {
                $( "#login_form_faq_text_2" ).slideDown( "slow" );
            } else {
                $( "#login_form_faq_text_2" ).slideUp( "slow" );
            }
        });

        $('#login_form_faq_button_3').click(function () {
            event.preventDefault();
            if ( $("#login_form_faq_text_3").first().is( ":hidden" ) ) {
                $( "#login_form_faq_text_3" ).slideDown( "slow" );
            } else {
                $( "#login_form_faq_text_3" ).slideUp( "slow" );
            }
        });

        $('#login_form_faq_button_4').click(function () {
            event.preventDefault();
            if ( $("#login_form_faq_text_4").first().is( ":hidden" ) ) {
                $( "#login_form_faq_text_4" ).slideDown( "slow" );
            } else {
                $( "#login_form_faq_text_4" ).slideUp( "slow" );
            }
        });

        $('#login_form_faq_button_5').click(function () {
            event.preventDefault();
            if ( $("#login_form_faq_text_5").first().is( ":hidden" ) ) {
                $( "#login_form_faq_text_5" ).slideDown( "slow" );
            } else {
                $( "#login_form_faq_text_5" ).slideUp( "slow" );
            }
        });

        $('#login_form_faq_button_6').click(function () {
            event.preventDefault();
            if ( $("#login_form_faq_text_6").first().is( ":hidden" ) ) {
                $( "#login_form_faq_text_6" ).slideDown( "slow" );
            } else {
                $( "#login_form_faq_text_6" ).slideUp( "slow" );
            }
        });

        $('#login_form_faq_button_7').click(function () {
            event.preventDefault();
            if ( $("#login_form_faq_text_7").first().is( ":hidden" ) ) {
                $( "#login_form_faq_text_7" ).slideDown( "slow" );
            } else {
                $( "#login_form_faq_text_7" ).slideUp( "slow" );
            }
        });

        $('#login_form_faq_button_8').click(function () {
            event.preventDefault();
            if ( $("#login_form_faq_text_8").first().is( ":hidden" ) ) {
                $( "#login_form_faq_text_8" ).slideDown( "slow" );
            } else {
                $( "#login_form_faq_text_8" ).slideUp( "slow" );
            }
        });


        $('#login_form_faq_button_9').click(function () {
            event.preventDefault();
            if ( $("#login_form_faq_text_9").first().is( ":hidden" ) ) {
                $( "#login_form_faq_text_9" ).slideDown( "slow" );
            } else {
                $( "#login_form_faq_text_9" ).slideUp( "slow" );
            }
        });

        $('#login_form_faq_button_10').click(function () {
            event.preventDefault();
            if ( $("#login_form_faq_text_10").first().is( ":hidden" ) ) {
                $( "#login_form_faq_text_10" ).slideDown( "slow" );
            } else {
                $( "#login_form_faq_text_10" ).slideUp( "slow" );
            }
        });

        $('#login_form_faq_button_11').click(function () {
            event.preventDefault();
            if ( $("#login_form_faq_text_11").first().is( ":hidden" ) ) {
                $( "#login_form_faq_text_11" ).slideDown( "slow" );
            } else {
                $( "#login_form_faq_text_11" ).slideUp( "slow" );
            }
        });

        $('#login_form_faq_button_12').click(function () {
            event.preventDefault();
            if ( $("#login_form_faq_text_12").first().is( ":hidden" ) ) {
                $( "#login_form_faq_text_12" ).slideDown( "slow" );
            } else {
                $( "#login_form_faq_text_12" ).slideUp( "slow" );
            }
        });

    }

})