$(document).on('turbolinks:load', function () {

    // $(function() {
    //     $("#kendoVersion").text(kendo.version);
    // });

    // Organiation Edit page
    var organization_inn_field = document.querySelector('#organization_inn');

    if (organization_inn_field != undefined) {

        // search by inn
        var selected_inn = null;
        var selected_name_full_with_opf = null;
        var selected_name_short_with_opf = null;
        var selected_kpp = null;
        var selected_address = null;
        var selected_okved = null;
        var selected_ogrn = null;
        var selected_oktmo = null;
        var selected_okato = null;
        var selected_okpo = null;
        var selected_city = null;

        // search by bik
        var selected_bik = null;
        var selected_name_short = null;
        var selected_correspondent_account = null;
        var selected_bank_name = null;

        function activate_button_fill(button_id, activate) {
            if (activate) {
                $(button_id).attr("disabled", false);
                $(button_id).addClass("text-gray-700 bg-gray-50 hover:text-gray-500")
                $(button_id).removeClass("bg-gray-100 text-white opacity-80 cursor-not-allowed");
            } else {
                $(button_id).attr("disabled", true);
                $(button_id).removeClass("text-gray-700 bg-gray-50")
                $(button_id).addClass("bg-gray-100 text-white opacity-80 cursor-not-allowed");
            }
        }

        activate_button_fill("#suggestions_fill_by_inn", false);
        $('#suggestions_fill_by_inn').click(function (e) {
            e.preventDefault();
            // console.log('click');

            if (selected_inn) {

                $('#organization_full_w_opf_name').val(selected_name_full_with_opf);
                $('#organization_short_w_opf_name').val(selected_name_short_with_opf);
                $('#organization_kpp').val(selected_kpp);

                $('#organization_mailing_address').val(selected_address);
                $('#organization_legal_address').val(selected_address);
                $('#organization_actual_production_address').val(selected_address);
                $('#organization_city').val(selected_city);

                $('#organization_okved').val(selected_okved);
                $('#organization_ogrn').val(selected_ogrn);
                $('#organization_oktmo').val(selected_oktmo);
                $('#organization_okato').val(selected_okato);
                $('#organization_okpo').val(selected_okpo);

            }

        });

        activate_button_fill("#suggestions_fill_by_bik", false);
        $('#suggestions_fill_by_bik').click(function (e) {
            e.preventDefault();
            // console.log('click');

            if (selected_bik) {
                $('#organization_ks').val(selected_correspondent_account);
                $('#organization_bank_name').val(selected_bank_name);
            }

        });


        $("#organization_inn").kendoAutoComplete({
            dataTextField: "inn",
            filter: "contains",
            minLength: 10,
            template: '<span class="k-state-default"><b>#: data.inn #</b> &nbsp; <small>#: data.name_short_with_opf #</small></span>',
            select: function (e) {
                var dataItem = this.dataItem(e.item);

                if (dataItem) {

                    selected_inn = dataItem.inn;
                    selected_name_full_with_opf = dataItem.name_full_with_opf;
                    selected_name_short_with_opf = dataItem.name_short_with_opf;
                    selected_kpp = dataItem.kpp;
                    selected_address = dataItem.address;
                    selected_okved = dataItem.okved;
                    selected_ogrn = dataItem.ogrn;
                    selected_oktmo = dataItem.oktmo;
                    selected_okato = dataItem.okato;
                    selected_okpo = dataItem.okpo;
                    selected_city = dataItem.city;

                    // console.log(dataItem.inn);
                    // console.log(dataItem.name_full_with_opf);
                    // console.log(dataItem.name_short_with_opf);
                    // console.log(dataItem.kpp);
                    // console.log(dataItem.address);
                    // console.log(dataItem.okved);
                    // console.log(dataItem.ogrn);
                    // console.log(dataItem.oktmo);
                    // console.log(dataItem.okato);
                    // console.log(dataItem.okpo);
                    // console.log(dataItem.city);

                    activate_button_fill("#suggestions_fill_by_inn", true);
                    // console.log('enable');
                } else {
                    activate_button_fill("#suggestions_fill_by_inn", false);
                    // console.log('disable');
                }


            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: "/suggestions.json",
                    data: {
                        filter: {field: "by_inn", operator: "eq", value: true}
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    model: {
                        fields: {
                            inn: {type: "string"},
                            name_full_with_opf: {type: "string"},
                            name_short_with_opf: {type: "string"},
                            kpp: {type: "string"},
                            address: {type: "string"},
                            okved: {type: "string"},
                            ogrn: {type: "string"},
                            oktmo: {type: "string"},
                            okato: {type: "string"},
                            okpo: {type: "string"},
                            city: {type: "string"},
                        }
                    }
                }
            }
        });


        $("#organization_bik").kendoAutoComplete({
            dataTextField: "bik",
            filter: "contains",
            minLength: 3,
            template: '<span class="k-state-default"><b>#: data.bik #</b> &nbsp; <small>#: data.bank_name #</small></span>',
            select: function (e) {
                var dataItem = this.dataItem(e.item);

                if (dataItem) {
                    activate_button_fill("#suggestions_fill_by_bik", true);
                    // console.log('enable');
                } else {
                    activate_button_fill("#suggestions_fill_by_bik", false);
                    // console.log('disable');
                }

                selected_bik = dataItem.bik;
                selected_name_short = dataItem.name_short;
                selected_correspondent_account = dataItem.correspondent_account;
                selected_bank_name = dataItem.bank_name;

                // console.log(dataItem.bik);
                // console.log(dataItem.name_short);
                // console.log(dataItem.correspondent_account);
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: "/suggestions.json",
                },
                schema: {
                    total: "total",
                    data: "results",
                    model: {
                        fields: {
                            bik: {type: "string"},
                            name_short: {type: "string"},
                            correspondent_account: {type: "string"},
                            bank_name: {type: "string"}
                        }
                    }
                }
            }
        });


        $("#organization_tel").kendoMaskedTextBox({
            mask: "+7 (000) 000-0000"
        });

        $("#organization_fax").kendoMaskedTextBox({
            mask: "+7 (000) 000-0000"
        });


    }

    // Organiation Index page
    var organizations_table = document.querySelector('#organizations_table');

    if (organizations_table != undefined) {

        function organizations_table_resize() {
            //grid auto heigh
            $("#organizations_table").children(".k-grid-content").height($(window).height() - 330);

        }

        $(window).resize(function () {
            organizations_table_resize();
        });


        function organizations_table_row_coloring() {
            var grid = $("#organizations_table").data("kendoGrid");
            var data = grid.dataSource.data();

            $.each(data, function (i, row) {

                if (row.contract_signed == true) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#f0fcef"); //yellow (Создана)
                } else {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#fff3f5"); //red (Ожидает оплаты)
                }
            });
        }


        var organizations_table_grid = $("#organizations_table").kendoGrid({

            dataSource: {

                // sort: {field: "invoice_date", dir: "desc"},

                transport: {
                    read: "/organizations.json"
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            contract_number: {type: "string"},
                            contract_signed: {type: "boolean"},
                            contract_uploaded: {type: "boolean"},
                            short_w_opf_name: {type: "string"},
                            inn: {type: "string"},
                        }
                    }
                },

                pageSize: 100,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },

            // change: onSelectInvoice,
            dataBound: function (e) {
                organizations_table_resize();

                organizations_table_row_coloring();
            },
            sortable: true,
            reorderable: true,
            resizable: true,
            pageable: {
                refresh: true,
                // pageSizes: true,
                pageSizes: [30, 50, 100, 250], //, "all"
                buttonCount: 10,

                messages: {
                    itemsPerPage: "",
                    display: "{0} - {1} из {2} организаций"
                }
            },

            columnMenu: {
                sortable: false,
                filterable: false
            },
            selectable: "true",
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    // template: "<div align='center'><a class='btn btn-light btn-sm py-0' style='font-size: 11px;' href='/organizations/#=id#/'>View</a></div>",
                    template: "<span class=\"inline-flex rounded-md shadow-xs\"><a href='/organizations/#=id#/'><button type=\"button\" class=\"inline-flex items-center px-1.5 py-1 border text-xs leading-4 font-medium rounded border-transparent bg-gray-100 text-black hover:bg-gray-500 focus:outline-none focus:border-black-100 focus:shadow-outline-black active:bg-gray-600 transition ease-in-out duration-150\">view</button></a></span>",
                    width: 55,
                    hidden: false
                },
                {
                    // template: "<div align='center'><a class='btn btn-warning btn-sm py-0' style='font-size: 11px;' href='/organizations/#=id#/edit/'>Edit</a></div>",
                    template: "<span class='inline-flex rounded-md shadow-xs'><a href='/organizations/#=id#/edit/'><button type='button' class='inline-flex items-center px-1.5 py-1 border text-xs leading-4 font-medium rounded border-transparent bg-yellow-100 text-yellow-800 hover:bg-yellow-300 focus:outline-none focus:border-black-100 focus:shadow-outline-black active:bg-yellow-100 transition ease-in-out duration-150'>edit</button></a></span>",
                    width: 55,
                    hidden: false
                },
                {
                    title: "Номер договора",
                    field: "contract_number",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 200
                },
                {
                    title: "Договор загружен",
                    field: "contract_uploaded",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        },
                        messages: {isTrue: ' Y ', isFalse: " N "}
                    },

                    template: '<div align="center"># if (contract_uploaded == true) {# <span style="color: green"><i class="fas fa-check"></i></span> # } else { # <span style="color: red"><i class="fas fa-times-circle"></i></span> # } #</div>',
                    width: 100
                },
                {
                    title: "Договор подписан",
                    field: "contract_signed",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        },
                        messages: {isTrue: ' Y ', isFalse: " N "}
                    },

                    template: '<div align="center"># if (contract_signed == true) {# <span style="color: green"><i class="fas fa-check"></i></span> # } else { # <span style="color: red"><i class="fas fa-times-circle"></i></span> # } #</div>',
                    width: 150
                },
                {
                    title: "Организация",
                    field: "short_w_opf_name",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                },
                {
                    title: "ИНН",
                    field: "inn",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                }
            ]
        }).data("kendoGrid");

    }

})