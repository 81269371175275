$(document).on('turbolinks:load', function () {


    // $(function() {
    //     $("#kendoVersion").text(kendo.version);
    // });


    // Index page
    var passport_applications_table = document.querySelector('#passport_applications_table');

    if (passport_applications_table != undefined) {

        function grid_passport_applications_resize() {
            //grid auto heigh
            $("#passport_applications_table").children(".k-grid-content").height($(window).height() - 330);

        }

        $(window).resize(function () {
            grid_passport_applications_resize();
        });


        function grid_passport_applications_row_coloring() {
            var grid = $("#passport_applications_table").data("kendoGrid");
            var data = grid.dataSource.data();

            $.each(data, function (i, row) {

                if (row.status_number == 10) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#fdfcf3"); //yellow (Создана)
                } else if (row.status_number == 20) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#fff3f5"); //red (Ожидает оплаты)
                } else if (row.status_number == 30) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#f0fcef"); //green (Оплачена)
                } else if (row.status_number == 40) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#edf5fc"); //blue (Выполнена)
                }
            });
        }


        var passport_applications_table_grid = $("#passport_applications_table").kendoGrid({

            dataSource: {

                sort: {field: "created_at", dir: "desc"},

                transport: {
                    read: "/passport_applications.json"
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "string"},
                            created_at: {type: "date"},
                            organization_name: {type: "string"},
                            inn: {type: "string"},
                            request_number: {type: "string"},
                            technical_name: {type: "string"},
                            tradename: {type: "string"},
                            applicant_short_name: {type: "string"},
                            // passport_application_status_id: {type: "integer"},
                            // passport_application_status_name: {type: "string"},
                            passport_application_invoice_number: {type: "string"},
                            passport_application_progress: {type: "string"},
                            tariff_base_price: {type: "string"},
                            status_name: {type: "string"},
                            status_number: {type: "integer"},
                            status_class_name: {type: "string"},
                            days_to_complete: {type: "integer"}
                        }
                    }
                },

                pageSize: 100,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },

            // change: onSelectInvoice,
            dataBound: function (e) {
                grid_passport_applications_resize();

                grid_passport_applications_row_coloring();


                // var grid = this;
                // grid.table.find("tr").each(function () {
                //     var dataItem = grid.dataItem(this);
                //     var themeColor = dataItem.status_number == 10 ? 'success' : 'warning';
                //
                //
                //     var text = dataItem.passport_application_status_name;
                //
                //     // console.log(dataItem);
                //     // console.log(themeColor);
                //     // console.log(text);
                //
                //     $(this).find(".badgeTemplate").kendoBadge({
                //         themeColor: themeColor,
                //         text: text,
                //     });
                //
                //
                //     kendo.bind($(this), dataItem);
                // });


                // rowColoring(e);
            },
            sortable: true,
            reorderable: true,
            resizable: true,
            pageable: {
                refresh: true,
                // pageSizes: true,
                pageSizes: [30, 50, 100, 250], //, "all"
                buttonCount: 10,

                messages: {
                    itemsPerPage: "",
                    display: "{0} - {1} из {2} заявок"
                }
            },

            columnMenu: {
                sortable: false,
                filterable: false
            },
            selectable: "true",
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    // template: "<div align='center'><a class='btn btn-light btn-sm py-0' style='font-size: 11px;' href='/passport_applications/#=id#/'>View</a></div>",
                    template: "<span class=\"inline-flex rounded-md shadow-xs\"><a href='/passport_applications/#=id#/'><button type=\"button\" class=\"inline-flex items-center px-1.5 py-1 border text-xs leading-4 font-medium rounded border-transparent bg-gray-100 text-black hover:bg-gray-500 focus:outline-none focus:border-black-100 focus:shadow-outline-black active:bg-gray-600 transition ease-in-out duration-150\">view</button></a></span>",
                    width: 55,
                },
                {
                    // template: "<div align='center'><a class='btn btn-warning btn-sm py-0' style='font-size: 11px;' href='/passport_applications/#=id#/edit/'>Edit</a></div>",
                    // template: '<div align="center"><span style="color: green;"># if (is_paid) {# <i class="far fa-ruble-sign"></i> #}# </span></div>',
                    template: "<span class='inline-flex rounded-md shadow-xs'># if (status_number <= 20) {#<a href='/passport_applications/#=id#/edit/'><button type='button' class='inline-flex items-center px-1.5 py-1 border text-xs leading-4 font-medium rounded border-transparent bg-yellow-100 text-yellow-800 hover:bg-yellow-300 focus:outline-none focus:border-black-100 focus:shadow-outline-black active:bg-yellow-100 transition ease-in-out duration-150'>edit</button></a>#}#</span>",
                    width: 55,
                },
                {
                    title: "Номер заявки",
                    field: "id",
                    width: 70,
                    template: '<div align="center"><b> #= id # </b></div>',
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                },
                {
                    title: "Организация",
                    field: "organization_name",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 150,
                },
                {
                    title: "ИНН",
                    field: "inn",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 130,
                },
                {
                    title: "Номер СТО, ГОСТ или ТУ",
                    field: "request_number",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 150
                },
                {
                    title: "Техническое название",
                    field: "technical_name",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    sortable: false,
                },
                {
                    title: "Торговое название",
                    field: "tradename",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                },
                {
                    title: "Название",
                    field: "applicant_short_name",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                },
                {
                    title: "Статус",
                    field: "status_name",

                    //<span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-gray-100 text-gray-800">   </span>
                    // template: "<span id='badge_#=passport_application_status_name#' class='badgeTemplate' ></span>",

                    template: '<span class="#= status_class_name #" >#= status_name #</span>',
                    filterable: false,
                    sortable: false,
                    width: 135
                },
                {
                    title: "Дней до выполнения",
                    field: "days_to_complete",

                    template: '# if (status_number == 30) {# <i class="fal fa-history"></i> #= days_to_complete # #}#',
                    filterable: false,
                    sortable: false,
                    width: 55
                },
                {
                    title: "Счета",
                    field: "passport_application_invoice_number",
                    template: '# if (passport_application_invoice_number) {# <div align="center"><b><a href="/invoices/#=passport_application_invoice_number#/"><button type="button" class="inline-flex items-center px-2.5 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> #= passport_application_invoice_number # </button></a></b></div> #}#',
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    sortable: false,
                    width: 60
                },
                {
                    title: "Тариф",
                    field: "tariff_base_price",
                    template: '<div align="right"><b>#= tariff_base_price # <i class="fal fa-ruble-sign"></i></b></div>',
                    filterable: false,
                    sortable: false,
                    width: 70
                },
            ]
        }).data("kendoGrid");


        // function rowColoring(e) {
        //     var grid = this;
        //     grid.table.find("tr").each(function () {
        //         var dataItem = grid.dataItem(this);
        //         var themeColor = dataItem.status_number == 10 ? 'success' : 'error';
        //         var text = dataItem.passport_application_status_name;
        //
        //         console.log(dataItem);
        //         console.log(themeColor);
        //         console.log(text);
        //
        //         $(this).find(".badgeTemplate").kendoBadge({
        //             themeColor: themeColor,
        //             text: text,
        //         });
        //
        //
        //         kendo.bind($(this), dataItem);
        //     });
        //
        // }

    }


    // Edit form
    var passport_applications_form = document.querySelector('#passport_application_request_number');


    if (passport_applications_form != undefined) {

        set_tariff_plan_description_and_price(gon.passport_tariff_plan_description, gon.passport_tariff_plan_base_price);

        function set_tariff_plan_description_and_price(description, price) {

            $('#tariff_plan_description').html(description);
            $('#tariff_plan_base_price').html(price);
        }


        $("#passport_application_organization_id").kendoDropDownList({
            filter: "startswith",
            dataTextField: "short_w_opf_name",
            dataValueField: "id",
            template: '<span class="k-state-default"><h3>#: data.short_w_opf_name #</h3><p><small>ИНН: #: data.inn #</small></p></span>',
            dataSource: {
                // type: "odata",
                serverFiltering: true,
                transport: {
                    read: {
                        url: "/organizations.json",
                        data: {
                            filter: {
                                filters: [
                                    {
                                        field: "blank",
                                        operator: "eq",
                                        value: true
                                    },
                                    {
                                        field: "signed",
                                        operator: "eq",
                                        value: true
                                    },
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            short_w_opf_name: {type: "string"},
                            contract_number: {type: "string"},
                            inn: {type: "string"},
                            kpp: {type: "string"}
                        }
                    }
                },
            }
        });


        $("#passport_application_contact_tel").kendoMaskedTextBox({
            mask: "+7 (000) 000-0000"
        });

        $("#passport_application_contact_fax").kendoMaskedTextBox({
            mask: "+7 (000) 000-0000"
        });


        $("#passport_application_passport_tariff_plan_id").kendoDropDownList({
            dataTextField: "name",
            dataValueField: "id",
            // dataBound: function (e) {
            //     // console.log('databound');
            //     set_tariff_plan_description_and_price(gon.passport_tariff_plan_description, gon.passport_tariff_plan_base_price);
            //
            // },
            select: function (e) {
                console.log('select');

                if (e.dataItem) {
                    var dataItem = e.dataItem;
                    // console.log(dataItem.name + " : " + dataItem.description + ")");

                    set_tariff_plan_description_and_price(dataItem.description, dataItem.base_price);
                }

            },
            dataSource: {
                transport: {
                    read: {
                        url: "/passport_tariff_plans.json",
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name: {type: "string"},
                            description: {type: "string"},
                            maximum_days: {type: "string"},
                            base_price: {type: "string"}
                        }
                    }
                },
            }
        });
    }


})