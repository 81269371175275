$(document).on('turbolinks:load', function () {

    // $(function() {
    //     $("#kendoVersion").text(kendo.version);
    // });


    // Show invoice page
    var invoices_show = document.querySelector('#pdf-viewer-wrapper-div');

    if (invoices_show != undefined) {

        function invoices_pdf_resize() {
            //grid auto heigh
            $("#pdf-viewer-wrapper-div").height($(window).height() - 400);

        }

        $(window).resize(function () {
            invoices_pdf_resize();
        });

        invoices_pdf_resize();

    }



    // Edit invoice
    var invoice_edit = document.querySelector('#invoice_day_of_payment');
    if (invoice_edit != undefined) {

        $("#invoice_day_of_payment").kendoDatePicker({
            format: "dd/MM/yyyy",
        });

        $("#invoice_date_of_done").kendoDatePicker({
            format: "dd/MM/yyyy",
        });

        $("#invoice_date_of_close").kendoDatePicker({
            format: "dd/MM/yyyy",
        });

    }


    // Index invoice page
    var invoices_table = document.querySelector('#invoices_table');

    if (invoices_table != undefined) {

        // console.log('invoices');

        function invoices_table_resize() {
            //grid auto heigh
            $("#invoices_table").children(".k-grid-content").height($(window).height() - 330);

        }

        $(window).resize(function () {
            invoices_table_resize();
        });


        var invoices_table_grid = $("#invoices_table").kendoGrid({

            dataSource: {

                // sort: {field: "invoice_date", dir: "desc"},

                transport: {
                    read: "/invoices.json"
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "string"},
                            created_at: {type: "date"},
                            date_of_invoice: {type: "date"},
                            day_of_payment: {type: "date"},
                            date_of_done: {type: "date"},
                            date_of_close: {type: "date"},

                            applications_count: {type: "string"},
                            invoice_total_rubles: {type: "string"},

                            status_name: {type: "string"},
                            status_number: {type: "integer"},
                            status_class_name: {type: "string"},

                            is_edit_allow: {type: "boolean"}
                        }
                    }
                },

                pageSize: 100,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },

            // change: onSelectInvoice,
            dataBound: function (e) {
                invoices_table_resize();
            },
            sortable: {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true
            },
            reorderable: true,
            resizable: true,
            pageable: {
                refresh: true,
                // pageSizes: true,
                pageSizes: [30, 50, 100, 250], //, "all"
                buttonCount: 10,

                messages: {
                    itemsPerPage: "",
                    display: "{0} - {1} из {2} счетов"
                }
            },

            // columnMenu: {
            //     sortable: false,
            //     filterable: false
            // },
            selectable: "true",
            filterable: {
                mode: "row"
            },

            columns: [
                {
                    // template: "<div align='center'><a class='btn btn-light btn-sm py-0' style='font-size: 11px;' href='/invoices/#=id#/'>View</a></div>",
                    template: "<span class=\"inline-flex rounded-md shadow-xs\"><a href='/invoices/#=id#/'><button type=\"button\" class=\"inline-flex items-center px-1.5 py-1 border text-xs leading-4 font-medium rounded border-transparent bg-gray-100 text-black hover:bg-gray-500 focus:outline-none focus:border-black-100 focus:shadow-outline-black active:bg-gray-600 transition ease-in-out duration-150\">view</button></a></span>",
                    width: 55,
                    hidden: false
                },
                {
                    // template: "<div align='center'><a class='btn btn-warning btn-sm py-0' style='font-size: 11px;' href='/invoices/#=id#/edit/'>Edit</a></div>",
                    template: "# if ((status_number <= 20) || (is_edit_allow == true)) {#<span class='inline-flex rounded-md shadow-xs'><a href='/invoices/#=id#/edit/'><button type='button' class='inline-flex items-center px-1.5 py-1 border text-xs leading-4 font-medium rounded border-transparent bg-yellow-100 text-yellow-800 hover:bg-yellow-300 focus:outline-none focus:border-black-100 focus:shadow-outline-black active:bg-yellow-100 transition ease-in-out duration-150'>edit</button></a></span> #}#",

                    // template: ' # if ( (status_number <= 20)  )  {# aaa # } # ',

                    width: 55,
                    hidden: false
                },
                {
                    title: "Счет",

                    filterable: false,
                    template: '<span class="text-medium text-base"><a href="/invoices/#=id#.pdf"><i class="far fa-file-invoice"></i></a></span>',
                    width: 40
                },
                {
                    title: "Номер счета",
                    field: "id",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 90
                },
                {
                    title: "Статус",
                    template: '<span class="#= status_class_name #" >#= status_name #</span>',
                    field: "status_name",
                    filterable: false,
                    width: 110
                },
                {
                    title: "Создан",
                    field: "created_at",
                    type: "date",
                    format: "{0:dd.MM.yyyy | HH:mm:ss}",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 150
                },
                {
                    title: "Выставлен",
                    field: "date_of_invoice",
                    type: "date",
                    format: "{0:dd/MM/yyyy}",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 150
                },
                {
                    title: "Оплачен",
                    field: "day_of_payment",
                    type: "date",
                    format: "{0:dd/MM/yyyy}",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    sortable: false,
                    width: 150
                },
                {
                    title: "Выполнен",
                    field: "date_of_done",
                    type: "date",
                    format: "{0:dd/MM/yyyy}",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 150
                },
                {
                    title: "Закрыт",
                    field: "date_of_close",
                    format: "{0:dd/MM/yyyy}",
                    type: "date",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 150
                },
                {
                    title: "Кол-во заявок",
                    field: "applications_count",
                    filterable: false,
                    sortable: false,
                    width: 50
                },
                {
                    title: "Стоимость, руб",
                    field: "invoice_total_rubles",
                    template: '<div align="right"><b>#= invoice_total_rubles # <i class="fal fa-ruble-sign"></i></b></div>',
                    filterable: false,
                    sortable: false,
                    width: 100
                }
            ]
        }).data("kendoGrid");


        function filterInvoiceMenu(e) {
            console.log('filter menu');
            if (e.field == "created_at") {
                console.log('filter menu created at');
                $('input[data-role="datepicker"]').kendoDatePicker({format: "dd-MM-yyyy"});
            }
        }


    }


})