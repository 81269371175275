// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("@nathanvda/cocoon")
// require("@progress/kendo-ui")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "@fortawesome/fontawesome-pro/js/all";

import "../stylesheets/application.scss"


import "../components/organization"
import "../components/passport_application"
import "../components/invoice"
import "../components/document"
import "../components/login_form"

// import "@progress/kendo-ui/js/kendo.all.min"

// import '@progress/kendo-ui-core';


$(document).on('turbolinks:load', function () {

    kendo.culture("ru-RU");

    $("#user-menu").click(function (e) {
        e.preventDefault();

        $("#user_drop_down_menu").toggleClass("hidden");

    });


    (function worker() {
        $.ajax({
            url: '/overal_statuses.json',
            dataType: 'json',
            success: function (data) {

                // organizations
                if (data.organizations_no_contract == 0) {
                    data.organizations_no_contract = '';
                    $('#organizations_no_contract').hide();
                } else {
                    $('#organizations_no_contract').show();
                }

                if (data.organizations_no_sign == 0) {
                    data.organizations_no_sign = '';
                    $('#organizations_no_sign').hide();
                } else {
                    $('#organizations_no_sign').show();
                }

                if (data.organizations_ok == 0) {
                    data.organizations_ok = '';
                    $('#organizations_ok').hide();
                } else {
                    $('#organizations_ok').show();
                }

                // passport_applications
                if (data.passport_applications_created == 0) {
                    data.passport_applications_created = '';
                    $('#passport_applications_created').hide();
                } else {
                    $('#passport_applications_created').show();
                }

                if (data.passport_applications_wait_payment == 0) {
                    data.passport_applications_wait_payment = '';
                    $('#passport_applications_wait_payment').hide();
                } else {
                    $('#passport_applications_wait_payment').show();
                }

                if (data.passport_applications_paid == 0) {
                    data.passport_applications_paid = '';
                    $('#passport_applications_paid').hide();
                } else {
                    $('#passport_applications_paid').show();
                }

                if (data.passport_applications_done == 0) {
                    data.passport_applications_done = '';
                    $('#passport_applications_done').hide();
                } else {
                    $('#passport_applications_done').show();
                }

                if (data.passport_applications_done_and_closed == 0) {
                    data.passport_applications_done_and_closed = '';
                    $('#passport_applications_done_and_closed').hide();
                } else {
                    $('#passport_applications_done_and_closed').show();
                }

                // documents
                if (data.documents_done == 0) {
                    data.documents_done = '';
                    $('#documents_done').hide();
                } else {
                    $('#documents_done').show();
                }

                if (data.documents_done_and_closed == 0) {
                    data.documents_done_and_closed = '';
                    $('#documents_done_and_closed').hide();
                } else {
                    $('#documents_done_and_closed').show();
                }

                // invoices
                if (data.invoices_created == 0) {
                    data.invoices_created = '';
                    $('#invoices_created').hide();
                } else {
                    $('#invoices_created').show();
                }

                if (data.invoices_wait_payment == 0) {
                    data.invoices_wait_payment = '';
                    $('#invoices_wait_payment').hide();
                } else {
                    $('#invoices_wait_payment').show();
                }

                if (data.invoices_paid == 0) {
                    data.invoices_paid = '';
                    $('#invoices_paid').hide();
                } else {
                    $('#invoices_paid').show();
                }

                if (data.invoices_done == 0) {
                    data.invoices_done = '';
                    $('#invoices_done').hide();
                } else {
                    $('#invoices_done').show();
                }

                if (data.invoices_done_and_closed == 0) {
                    data.invoices_done_and_closed = '';
                    $('#invoices_done_and_closed').hide();
                } else {
                    $('#invoices_done_and_closed').show();
                }

                // documents
                if (data.documents_done == 0) {
                    data.documents_done = '';
                    $('#documents_done').hide();
                } else {
                    $('#documents_done').show();
                }

                if (data.documents_done_and_closed == 0) {
                    data.documents_done_and_closed = '';
                    $('#documents_done_and_closed').hide();
                } else {
                    $('#documents_done_and_closed').show();
                }

                // organizations
                $('#organizations_no_contract').html('<small><i class="fal fa-exclamation-triangle"></i></small><b>&nbsp;' + data.organizations_no_contract + '</b>');
                $('#organizations_no_sign').html('<small><i class="fal fa-pencil-alt"></i></small><b>&nbsp;' + data.organizations_no_sign + '</b>');
                $('#organizations_ok').html('<small><i class="far fa-check"></i></small><b>&nbsp;' + data.organizations_ok + '</b>');

                // passport_applications
                $('#passport_applications_created').html('<small><i class="far fa-plus"></i></small><b>&nbsp;' + data.passport_applications_created + '</b>');
                $('#passport_applications_wait_payment').html('<small><i class="fal fa-clock"></i></small><b> &nbsp;' + data.passport_applications_wait_payment + '</b>');
                $('#passport_applications_paid').html('<small><i class="fal fa-ruble-sign"></i></small><b> &nbsp; ' + data.passport_applications_paid  + '</b>');
                $('#passport_applications_done').html('<small><i class="far fa-check"></i></small><b> &nbsp;' + data.passport_applications_done + '</b>');
                $('#passport_applications_done_and_closed').html('<small><i class="far fa-check-double"></i></small><b> &nbsp;' + data.passport_applications_done_and_closed + '</b>');

                // invoices
                $('#invoices_created').html('<small><i class="far fa-plus"></i></small><b>&nbsp;' + data.invoices_created + '</b>');
                $('#invoices_wait_payment').html('<small><i class="fal fa-clock"></i></small><b> &nbsp;' + data.invoices_wait_payment + '</b>');
                $('#invoices_paid').html('<small><i class="fal fa-ruble-sign"></i></small><b> &nbsp; ' + data.invoices_paid + '</b>');
                $('#invoices_done').html('<small><i class="far fa-check"></i></small><b> &nbsp;' + data.invoices_done + '</b>');
                $('#invoices_done_and_closed').html('<small><i class="far fa-check-double"></i></small><b> &nbsp;' + data.invoices_done_and_closed + '</b>');

                // documents
                $('#documents_done').html('<small><i class="far fa-check"></i></small><b> &nbsp;' + data.documents_done + '</b>');

            },
            complete: function () {
                // Schedule the next request when the current one's complete
                // setTimeout(worker, 30000);

                //// TODO uncomment Worker in siedekiq.yml
                // setTimeout(worker, 60000);
            }
        });
    })();

});