$(document).on('turbolinks:load', function () {


    var document_edit = document.querySelector('#document_title_copy_template_to_client');

    if (document_edit != undefined) {

        function get_link_document_name(document_type) {
            var link_document_name = '';

            switch (document_type) {
                case 'title':
                    link_document_name = $("#link_document_title");
                    break;

                case 'text':
                    link_document_name = $("#link_document_text");
                    break;

                case 'request_to_develop':
                    link_document_name = $("#link_document_request_to_develop");
                    break;

                case 'request_to_register':
                    link_document_name = $("#link_document_request_to_register");
                    break;

                case 'composition':
                    link_document_name = $("#link_document_composition");
                    break;


                case 'covering_letter':
                    link_document_name = $("#link_document_covering_letter");
                    break;


                default:
                    // alert( "Error on file copy" );
                    link_document_name = null;
            }

            return link_document_name;
        }

        function copy_document(document_type) {

            $.ajax({
                type: "POST",
                url: "/documents/",
                data: {
                    passport_application: {
                        document_id: gon.document_id,
                        document_type: document_type,
                        document_action: 'copy_template_to_client'
                    }

                },
                beforeSubmit: function (arr, $form, options) {

                    link_document_name = get_link_document_name(document_type);
                    link_document_name.html('<span style="color: #22DA2B"><i class="fad fa-spinner-third fa-spin"></i></span> Сopying');
                },
                success: function (result) {

                    link_document_name = get_link_document_name(document_type);

                    // console.log(result.document_url);

                    if (link_document_name) {
                        link_document_name.attr("href", result.document_url)
                        link_document_name.html('<span style="color: #22DA2B"><i class="fas fa-check"></i></span> ' + result.document_original_filename)
                        link_document_name.fadeOut(100);
                        link_document_name.fadeIn(2000);
                    }


                },
                error: function (result) {
                    // alert( 'Error: Document not copied' );
                    link_document_name.html('Error: Document not copied');
                }
            });

        }


        $("#document_title_copy_template_to_client").click(function (e) {
            e.preventDefault();

            copy_document('title');
        });

        $("#document_text_copy_template_to_client").click(function (e) {
            e.preventDefault();

            copy_document('text');
        });

        $("#document_request_to_develop_copy_template_to_client").click(function (e) {
            e.preventDefault();

            copy_document('request_to_develop');
        });

        $("#document_request_to_register_copy_template_to_client").click(function (e) {
            e.preventDefault();

            copy_document('request_to_register');
        });

        $("#document_composition_copy_template_to_client").click(function (e) {
            e.preventDefault();

            copy_document('composition');
        });

        $("#document_covering_letter_copy_template_to_client").click(function (e) {
            e.preventDefault();

            copy_document('covering_letter');
        });


    }


    var documents_table = document.querySelector('#documents_table');

    if (documents_table != undefined) {

        function documents_table_resize() {
            //grid auto heigh
            $("#documents_table").children(".k-grid-content").height($(window).height() - 290);

        }

        $(window).resize(function () {
            documents_table_resize();
        });


        var documents_table_grid = $("#documents_table").kendoGrid({

            dataSource: {

                // sort: {field: "invoice_date", dir: "desc"},

                transport: {
                    read: "/documents.json"
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "string"},
                            organization_name: {type: "string"},
                            inn: {type: "string"},
                            request_number: {type: "string"},
                            technical_name: {type: "string"},
                            tradename: {type: "string"},
                            applicant_short_name: {type: "string"},
                            status_number: {type: "integer"},
                            status_name: {type: "string"},
                            status_class_name: {type: "string"},
                            passport_application_invoice_number: {type: "string"},
                            passport_application_progress: {type: "string"},
                            send_to_customer: {type: "boolean"},
                            is_edit_allow: {type: "boolean"},
                            days_to_complete: {type: "integer"}
                        }
                    }
                },

                pageSize: 100,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },

            // change: onSelectInvoice,
            dataBound: function (e) {
                documents_table_resize();


                var grid = this;
                grid.table.find("tr").each(function () {
                    var dataItem = grid.dataItem(this);
                    var themeColor = dataItem.passport_application_status_id == 1 ? 'success' : 'warning';


                    var text = dataItem.passport_application_status_name;

                    // console.log(dataItem);
                    // console.log(themeColor);
                    // console.log(text);

                    $(this).find(".badgeTemplate").kendoBadge({
                        themeColor: themeColor,
                        text: text,
                    });


                    kendo.bind($(this), dataItem);
                });



                // if (gon.is_edit_allow) {
                //
                //     $("#documents_table").data("kendoGrid").showColumn(1);
                //     $("#documents_table").data("kendoGrid").showColumn(11);
                // }
                //
                // if (gon.is_show_contract_number) {
                //     $("#documents_table").data("kendoGrid").showColumn(2);
                // }

                // rowColoring(e);
            },
            sortable: true,
            reorderable: true,
            resizable: true,
            pageable: {
                refresh: true,
                // pageSizes: true,
                pageSizes: [30, 50, 100, 250], //, "all"
                buttonCount: 10,

                messages: {
                    itemsPerPage: "",
                    display: "{0} - {1} из {2} заявок"
                }
            },

            // columnMenu: {
            //     sortable: false,
            //     filterable: false
            // },
            columnMenu: false,

            selectable: "true",
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    // template: "<div align='center'><a class='btn btn-light btn-sm py-0' style='font-size: 11px;' href='/passport_applications/#=id#/'>View</a></div>",
                    template: "<span class='inline-flex rounded-md shadow-xs'><a href='/documents/#=id#/'><button type='button' class='grid_button_show'>view</button></a></span>",
                    width: 50,
                },
                {
                    // template: "<div align='center'><a class='btn btn-warning btn-sm py-0' style='font-size: 11px;' href='/passport_applications/#=id#/edit/'>Edit</a></div>",
                    // template: '<div align="center"><span style="color: green;"># if (is_paid) {# <i class="far fa-ruble-sign"></i> #}# </span></div>',
                    // template: "<span class='inline-flex rounded-md shadow-xs'><a href='/documents/#=id#/edit/'><button type='button' class='inline-flex items-center px-1.5 py-1 border text-xs leading-4 font-medium rounded border-transparent bg-yellow-100 text-yellow-800 hover:bg-yellow-300 focus:outline-none focus:border-black-100 focus:shadow-outline-black active:bg-yellow-100 transition ease-in-out duration-150'>edit</button></a></span>",

                    template: "# if (is_edit_allow) {# <span class='inline-flex rounded-md shadow-xs'><a href='/documents/#=id#/edit/'><button type='button' class='grid_button_edit'>edit</button></a></span> #}#",
                    width: 50,
                    // hidden: true
                },
                {
                    title: "Номер договора",
                    field: "contract_number",
                    width: 70,
                    template: '<div align="center"><b> #= contract_number # </b></div>',
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    // hidden: true
                },
                {
                    title: "Номер заявки",
                    field: "id",
                    width: 70,
                    template: '<div align="center"><b> #= id # </b></div>',
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                },
                {
                    title: "Организация",
                    field: "organization_name",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 150,
                },
                // {
                //     title: "ИНН",
                //     field: "inn",
                //     filterable: {
                //         toolbar: false,
                //         cell: {
                //             showOperators: false,
                //             enabled: true
                //         }
                //     },
                //     width: 150,
                // },
                {
                    title: "Номер СТО, ГОСТ или ТУ",
                    field: "request_number",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 150
                },
                {
                    title: "Техническое название",
                    field: "technical_name",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    sortable: false,
                },
                {
                    title: "Торговое название",
                    field: "tradename",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                },
                {
                    title: "Название",
                    field: "applicant_short_name",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                },
                {
                    title: "Статус",
                    field: "status_name",

                    //<span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-gray-100 text-gray-800">   </span>
                    // template: "<span id='badge_#=status_name#' class='badgeTemplate'></span>",
                    template: '<span class="#= status_class_name #" >#= status_name #</span>',
                    filterable: false,
                    sortable: false,
                    width: 120
                },
                {
                    title: "Номер счета",
                    field: "passport_application_invoice_number",
                    // template: '<div align="center"><b> #= passport_application_invoice_number # </b></div>',
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    sortable: false,
                    width: 100
                },
                {
                    title: "Дней до выполнения",
                    field: "days_to_complete",

                    template: '# if (status_number == 30) {# <i class="fal fa-history"></i> #= days_to_complete # #}#',
                    filterable: false,
                    sortable: false,
                    width: 55,
                    hidden: true
                },
            ]
        }).data("kendoGrid");

    }


    var documents_table_a = document.querySelector('#documents_table_a');

    if (documents_table_a != undefined) {

        function documents_table_a_resize() {
            //grid auto heigh
            $("#documents_table_a").children(".k-grid-content").height($(window).height() - 290);

        }

        $(window).resize(function () {
            documents_table_a_resize();
        });


        var documents_table_a_grid = $("#documents_table_a").kendoGrid({

            dataSource: {

                // sort: {field: "invoice_date", dir: "desc"},

                transport: {
                    read: "/documents.json"
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "string"},
                            organization_name: {type: "string"},
                            inn: {type: "string"},
                            request_number: {type: "string"},
                            technical_name: {type: "string"},
                            tradename: {type: "string"},
                            applicant_short_name: {type: "string"},
                            status_number: {type: "integer"},
                            status_name: {type: "string"},
                            status_class_name: {type: "string"},
                            passport_application_invoice_number: {type: "string"},
                            passport_application_progress: {type: "string"},
                            send_to_customer: {type: "boolean"},
                            is_edit_allow: {type: "boolean"},
                            days_to_complete: {type: "integer"},
                            user_email: {type: "string"},
                        }
                    }
                },

                pageSize: 100,
                total: function (response) {
                    return response[0].TotalRecords;
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },

            // change: onSelectInvoice,
            dataBound: function (e) {
                documents_table_a_resize();


                var grid = this;
                grid.table.find("tr").each(function () {
                    var dataItem = grid.dataItem(this);
                    var themeColor = dataItem.passport_application_status_id == 1 ? 'success' : 'warning';


                    var text = dataItem.passport_application_status_name;

                    // console.log(dataItem);
                    // console.log(themeColor);
                    // console.log(text);

                    $(this).find(".badgeTemplate").kendoBadge({
                        themeColor: themeColor,
                        text: text,
                    });


                    kendo.bind($(this), dataItem);
                });


                var documents_table_grid_internal = $("#documents_table_a");
                if (gon.is_edit_allow) {

                    documents_table_grid_internal.data("kendoGrid").showColumn(1);
                    documents_table_grid_internal.data("kendoGrid").showColumn(11);
                }

                if (gon.is_show_contract_number) {
                    documents_table_grid_internal.data("kendoGrid").showColumn(2);
                }

                // rowColoring(e);
            },
            sortable: true,
            reorderable: true,
            resizable: true,
            pageable: {
                refresh: true,
                // pageSizes: true,
                pageSizes: [30, 50, 100, 250], //, "all"
                buttonCount: 10,

                messages: {
                    itemsPerPage: "",
                    display: "{0} - {1} из {2} заявок"
                }
            },

            // columnMenu: {
            //     sortable: false,
            //     filterable: false
            // },
            columnMenu: false,
            scrollable: true,
            selectable: "true",
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: "<span class='inline-flex rounded-md shadow-xs'><a href='/documents/#=id#/'><button type='button' class='inline-flex items-center px-1.5 py-1 border text-xs leading-4 font-medium rounded border-transparent bg-gray-100 text-black hover:bg-gray-500 focus:outline-none focus:border-black-100 focus:shadow-outline-black active:bg-gray-600 transition ease-in-out duration-150'>view</button></a></span>",
                    width: 55,
                },
                {
                    template: "<span class='inline-flex rounded-md shadow-xs'><a href='/documents/#=id#/edit/'><button type='button' class='inline-flex items-center px-1.5 py-1 border text-xs leading-4 font-medium rounded border-transparent bg-yellow-100 text-yellow-800 hover:bg-yellow-300 focus:outline-none focus:border-black-100 focus:shadow-outline-black active:bg-yellow-100 transition ease-in-out duration-150'>edit</button></a></span>",
                    width: 55,

                },
                {
                    title: "email",
                    field: "user_email",
                    template: '<div align="left"><b> #= user_email # </b></div>',
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 120,
                },
                {
                    title: "Номер договора",
                    field: "contract_number",
                    template: '<div align="center"><b> #= contract_number # </b></div>',
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 110,
                },
                {
                    title: "Статус",
                    field: "status_name",

                    //<span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-gray-100 text-gray-800">   </span>
                    // template: "<span id='badge_#=status_name#' class='badgeTemplate'></span>",
                    template: '<span class="#= status_class_name #" >#= status_name #</span>',
                    filterable: false,
                    sortable: false,
                    width: 120
                },
                {
                    title: "Дней до выполнения",
                    field: "days_to_complete",

                    template: '# if (status_number == 30) {# <i class="fal fa-history"></i> #= days_to_complete # #}#',
                    filterable: false,
                    sortable: false,
                    width: 55,
                    hidden: true
                },
                {
                    title: "Номер заявки",
                    field: "id",
                    template: '<div align="center"><b> #= id # </b></div>',
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 70,
                },
                {
                    title: "Организация",
                    field: "organization_name",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 150,
                },
                // {
                //     title: "ИНН",
                //     field: "inn",
                //     filterable: {
                //         toolbar: false,
                //         cell: {
                //             showOperators: false,
                //             enabled: true
                //         }
                //     },
                //     width: 150,
                // },
                {
                    title: "Номер СТО, ГОСТ или ТУ",
                    field: "request_number",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 150
                },
                {
                    title: "Техническое название",
                    field: "technical_name",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    sortable: false,
                    width: 150
                },
                // {
                //     title: "Торговое название",
                //     field: "tradename",
                //     filterable: {
                //         toolbar: false,
                //         cell: {
                //             showOperators: false,
                //             enabled: true
                //         }
                //     },
                //     width: 150
                // },
                // {
                //     title: "Название",
                //     field: "applicant_short_name",
                //     filterable: {
                //         toolbar: false,
                //         cell: {
                //             showOperators: false,
                //             enabled: true
                //         }
                //     },
                //     width: 150
                // },
                {
                    title: "Номер счета",
                    field: "passport_application_invoice_number",
                    // template: '<div align="center"><b> #= passport_application_invoice_number # </b></div>',
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    sortable: false,
                    width: 100
                },

            ]
        }).data("kendoGrid");

    }

});